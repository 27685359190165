import React from 'react'
import Components from 'components/'
import Layout from 'components/common/Layout'

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null
		}

		return StoryblokEntry.prepareStory(props)
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story)
		story.content = JSON.parse(story.content)

		return { story }
	}

	constructor(props) {
		super(props)

		this.state = StoryblokEntry.prepareStory(props)
	}

	render() {
		const { content } = this.state.story
		const { layout, seo } = content || {}

		return (
			<Layout
				headerData={layout.content && layout.content.header[0]}
				footerData={layout.content && layout.content.footer[0]}
				seo={seo}
			>
				{React.createElement(Components[content.component], {
					key: content._uid,
					blok: content,
				})}
			</Layout>
		)
	}
}

export default StoryblokEntry
